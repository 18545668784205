import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import '../ContactInfo.css'; // Assuming you have a CSS file for styles

const ContactInfo = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.language === 'ar';

    // Debug: log the phone numbers to verify
    console.log('Landline:', t('contact_info_landline_number'));
    console.log('Mobile:', t('contact_info_mobile_number'));

    return (
        <div className={`contact-info ${isRTL ? 'rtl' : ''}`}>
            <div className="contact-section">
                <h3>{t('contact_info_phone')}</h3>
                <p>
                    <a href={`tel:${t('contact_info_landline_number')}`}>
                        {t('contact_info_landline')}
                    </a>
                </p>
                <p>
                    <a href={`tel:${t('contact_info_mobile_number')}`}>
                        {t('contact_info_mobile')}
                    </a>
                </p>
            </div>
            <div className="contact-section">
                <h3>{t('contact_info_address')}</h3>
                <p>Onisilou 6, Limassol 4532</p>
            </div>
            <div className="contact-section">
                <h3>{t('contact_info_email')}</h3>
                <a href="mailto:info@msjmanagement.com">info@msjmanagement.com</a>
            </div>
            <div className="contact-section">
                <h3>{t('contact_info_social')}</h3>
                <a href="https://www.instagram.com/msj_management/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram size={30} />
                </a>
            </div>
        </div>
    );
};

export default ContactInfo;
