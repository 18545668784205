import React from 'react';
import { Link } from 'react-router-dom';
import '../Header.css';
import logo from '../images/LogowithText.png';
import LanguageSwitcher from './LanguageSwitcher';

function Header({ changeLanguage }) {
  console.log('changeLanguage in Header:', changeLanguage);

  return (
    <header className="header">
      <div className='header-section1'>
        <Link to="/">
          <div className='logo'>
            <img src={logo} alt="Logo" />
          </div>
        </Link>
      </div>
      <div className='header-section2'>
        <LanguageSwitcher changeLanguage={changeLanguage} />
      </div>
    </header>
  );
}

export default Header;
