// reducers/index.js
import { combineReducers } from 'redux';
import languageReducer from './languageReducer';

const rootReducer = combineReducers({
  language: languageReducer,
  // add other reducers here if needed
});

export default rootReducer;
