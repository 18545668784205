// ServicePageLayout.js
import React from 'react';
import '../ServicePageLayout.css';  // Ensure to create and link the CSS file
import ContactInfoBox from "../components/ContactInfoBox";
import ContactInfo from '../components/ContactInfo';
import '../ContactInfo.css';  // Ensure to create and link the CSS file
import logo from "../images/LogowithText.png";
import Map from '../components/Map'; // Import the Map component
import '../App.css';

const mapLocation = { lat: 34.709740083095575, lng: 33.12436871183346 }; // Coordinates for New York City


function ServicePageLayout({ image, title, description, children }) {
    return (
        <div className="service-page-layout">
            <div className="image-container">
                <img src={image} alt={title} />
            </div>
            <div className="content-container">
                <h1>{title}</h1>
                <p>{description}</p>
            </div>
            <div className="contact-form-container">
                {children} {/* This will render the contact form */}
            </div>
            <div className="footer-logo-container">
                <img src={logo} alt='logo'></img>
            </div>
            <div className="footer">
                <ContactInfo />
                <br />
                <div className='map-container'>
                <Map location={mapLocation} zoomLevel={15} /> {/* Add the Map component */}
                </div>

            </div>
        </div>
    );
}

export default ServicePageLayout;
