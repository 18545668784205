import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../App.css';
import '../i18n';

function ServiceBox({ image, title, description, path }) {
  const { t } = useTranslation(); // Import the useTranslation hook

  return (
    <div className="service-box">
      <img src={image} alt={title} />
      <div className="text-section">
        <h3>{title}</h3>
        <p>{description}</p>
        <button className="learn-more-button">
          <Link to={path}>
            {t('learn_more')}
          </Link>
        </button>
      </div>
    </div>
  );
}

export default ServiceBox;
