import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../Header.css';

const LanguageSwitcher = ({ changeLanguage }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const language = sessionStorage.getItem('language') || 'en';
    if (language !== i18n.language) {
      i18n.changeLanguage(language);
    }
    document.documentElement.lang = language;
    document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n]);

  const handleLanguageChange = (lng) => {
    console.log('LanguageSwitcher - handleLanguageChange:', lng);
    changeLanguage(lng); // Use the changeLanguage function passed as a prop
    sessionStorage.setItem('language', lng);
    document.documentElement.lang = lng;
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };

  return (
    <div className="language-switcher">
      <button onClick={() => handleLanguageChange('en')} className="language-button">EN</button>
      <button onClick={() => handleLanguageChange('ar')} className="language-button">AR</button>
    </div>
  );
};

export default LanguageSwitcher;
