// PropertyManagementPage.js
import React, { useEffect } from 'react';
import '../App.css';
import Header from '../components/Header';
import ServicePageLayout from '../components/ServicePageLayout';
import ServiceImage from '../images/Service1.jpg';
import { useTranslation } from 'react-i18next';
import '../i18n';
import ContactForm from '../components/ContactForm';

function PropertyManagementPage({ changeLanguage }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    window.scrollTo(0, 0);  // Scroll to the top of the page when the component mounts
  }, [i18n.language]);

  console.log('changeLanguage in PMP:', changeLanguage);

  const title = t('property_management_title');
  const description = t('property_management_description_service');

  return (
    <div>
      <Header changeLanguage={changeLanguage}/>
      <ServicePageLayout image={ServiceImage} title={title} description={description}>
        <ContactForm />
      </ServicePageLayout>
    </div>
  );
}

export default PropertyManagementPage;
