import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { useTranslation } from 'react-i18next';
import HomePage from './pages/HomePage';
import PropertyManagementPage from "./pages/PropertyManagementPage";
import PropertyRenovationPage from "./pages/PropertyRenovationPage";
import WeBuyPropertyPage from "./pages/WeBuyPropertyPage";
import './i18n';
import { store, persistor } from './redux/store';
import { changeLanguage as changeLanguageAction } from './redux/actions/languageActions';
import usePageRefresh from './hooks/usePageRefresh';
import { useLocation } from 'react-router-dom';

function App() {
  console.log("Google maps API key:", process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <AppContent />
        </Router>
      </PersistGate>
    </Provider>
  );
}

function AppContent() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector(state => state.language);

  usePageRefresh();

  const changeLanguage = (lng) => {
    console.log('Changing language to:', lng);
    i18n.changeLanguage(lng).then(() => {
      dispatch(changeLanguageAction(lng));
    });
  };

  useEffect(() => {
    console.log('App useEffect - Retrieved language from Redux store:', language);
    if (language && language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  }, [i18n, language]);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage changeLanguage={changeLanguage} />} />
        <Route path="/services/property_management" element={<PropertyManagementPage changeLanguage={changeLanguage} />} />
        <Route path="/services/property_renovation" element={<PropertyRenovationPage changeLanguage={changeLanguage} />} />
        <Route path="/services/property_purchase" element={<WeBuyPropertyPage changeLanguage={changeLanguage} />} />
      </Routes>
      <LanguageSync i18n={i18n} />
    </>
  );
}

function LanguageSync({ i18n }) {
  const location = useLocation();
  const language = useSelector(state => state.language);

  useEffect(() => {
    console.log('LanguageSync - Retrieved language from Redux store:', language);
    if (language && language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  }, [i18n, location.pathname, language]);

  return null;
}

export default App;
