import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';
import ServiceBox from '../components/ServiceBox';
import QuestionBox from '../components/QuestionBox';
import PropertyCarousel from '../components/PropertyCarousel';
import ContactForm from '../components/ContactForm';
import ContactInfo from '../components/ContactInfo';
import CookieConsent from 'react-cookie-consent';
import logo from '../images/LogowithText.png';
import heroBg from '../images/Hero-bg.png';
import about1 from '../images/about-1.png';
import about2 from '../images/about-2.png';
import service1Image from '../images/Service1.jpg';
import service2Image from '../images/Service2.jpg';
import service3Image from '../images/Service3.jpeg';
import Map from '../components/Map'; // Import the Map component
import '../App.css';
import '../i18n';

const HomePage = ({ changeLanguage }) => {
  const { t } = useTranslation();
  const mapLocation = { lat: 34.709740083095575, lng: 33.12436871183346 }; // Coordinates for New York City

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <div className="language-switcher">
            <LanguageSwitcher changeLanguage={changeLanguage} />
          </div>
        </div>
      </header>
      <div>
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="myAwesomeCookieName"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          {t('cookie_consent_text')}
        </CookieConsent>
      </div>
      <div className="fullscreen-container" id="hero">
        <div className="inner-container logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <div className="inner-container">
          <img src={heroBg} alt="Hero Background" className="hero-bg" />
        </div>
      </div>
      <div className="fullscreen-container grid-container">
        <div className="grid-item hero-title colored-title">
          {t('dedicated_management_services')}
        </div>
        <div className="grid-item">
          <p>{t('property_management_description')}</p>
        </div>
        <div className="grid-item">
          <img src={about1} alt="About" className="about-Image" />
        </div>
        <div className="grid-item">
          <img src={about2} alt="About" className="about-Image" />
        </div>
      </div>
      <header className="App-header">
        <div className="title">{t('what_we_offer')}</div>
        <div className="service-container">
          <ServiceBox
            image={service1Image}
            title={t('property_management_title')}
            description={t('property_management_description_service')}
            path="/services/property_management"
          />
          <ServiceBox
            image={service2Image}
            title={t('property_renovation_title')}
            description={t('property_renovation_description')}
            path="/services/property_renovation"
          />
          <ServiceBox
            image={service3Image}
            title={t('property_purchase_title')}
            description={t('property_purchase_description')}
            path="/services/property_purchase"
          />
        </div>
        <div className="property-container">
          <h2>{t('featured_properties')}</h2>
          <PropertyCarousel />
        </div>
        <div className="common-questions">
          <h2>{t('common_questions')}</h2>
          <div className="question-box-wrapper">
            <QuestionBox 
              question={t('question1')}
              answer={t('answer1')}
            />
            <QuestionBox 
              question={t('question2')}
              answer={t('answer2')}
            />
            <QuestionBox 
              question={t('question3')}
              answer={t('answer3')}
            />
          </div>
        </div>
        <div className="footer-logo-container">
          <img src={require("../images/LogowithText.png")} alt="Service 1" />
        </div>
        <div className="footer">
          <ContactForm />
          <br />
          <ContactInfo />
        </div>
      </header>
      <Map location={mapLocation} zoomLevel={15} /> {/* Add the Map component */}
    </div>
  );
};

export default HomePage;
