import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageRefresh = () => {
  const location = useLocation();

  useEffect(() => {
    const handlePageRefresh = () => {
      if (location.pathname === '/') {
        const hasReloaded = sessionStorage.getItem('hasReloaded');
        if (!hasReloaded) {
          sessionStorage.setItem('hasReloaded', 'true');
          window.location.reload();
        }
      }
    };

    handlePageRefresh();

    return () => {
      // Clear the session storage flag when navigating away from the homepage
      if (location.pathname !== '/') {
        sessionStorage.removeItem('hasReloaded');
      }
    };
  }, [location]);
};

export default usePageRefresh;
