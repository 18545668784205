// ServicePage1.js
import React, { useEffect } from 'react';
import '../App.css';
import Header from '../components/Header';
import ServicePageLayout from '../components/ServicePageLayout';
import ServiceImage from '../images/Service1.jpg';
import { useTranslation } from 'react-i18next';
import '../i18n';
import ContactForm from '../components/ContactForm';

function WeBuyPropertyPage({ changeLanguage }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Set the document language and direction
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [i18n.language]);

  const title = t('property_purchase_title');
  const description = t('property_purchase_description');

  return (
    <div>
      <Header changeLanguage={changeLanguage} />
      <ServicePageLayout image={ServiceImage} title={title} description={description}>
        <ContactForm />
      </ServicePageLayout>
    </div>
  );
}

export default WeBuyPropertyPage;
