import React, { useEffect } from 'react';
import '../App.css';
import Header from '../components/Header';
import ServicePageLayout from '../components/ServicePageLayout';
import ServiceImage from '../images/Service1.jpg';
import { useTranslation } from 'react-i18next';
import ContactForm from '../components/ContactForm';
import '../Header.css';

function PropertyRenovationPage({ changeLanguage }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = sessionStorage.getItem('language');
    console.log('PropertyRenovationPage - from session storage:', language);
    if (language && language !== i18n.language) {
      i18n.changeLanguage(language).then(() => {
        console.log('PropertyRenovationPage - Language set to:', language);
      });
    }

    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [i18n]);

  const title = t('property_renovation_title');
  const description = t('property_renovation_description');

  return (
    <div>
      <Header changeLanguage={changeLanguage} />
      <ServicePageLayout image={ServiceImage} title={title} description={description}>
        <ContactForm />
      </ServicePageLayout>
    </div>
  );
}

export default PropertyRenovationPage;
