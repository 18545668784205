import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';


const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const recaptchaRef = useRef(null);
    const { t } = useTranslation();

    const handleCaptchaChange = (token) => {
        
        if (token) {
            setCaptchaVerified(true);
            setCaptchaToken(token);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        if (!captchaVerified) {
            setResponseMessage('Please verify that you are not a robot');
            setIsSubmitting(false);
            return;
        }

        const formData = { name, email, message, captchaToken };



try {
      const response = await fetch('https://kms3a958g2.execute-api.eu-central-1.amazonaws.com/Prod/contact/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setResponseMessage('Form data submitted successfully.');
        setName('');
        setEmail('');
        setMessage('');
        recaptchaRef.current.reset();  // Reset the CAPTCHA
        setCaptchaVerified(false);     // Reset verification state
      } else {
        setResponseMessage('Form data submission failed.');
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('Form data submission failed.');
    }

    setIsSubmitting(false);
  };

  return (
    <div className="contact-form-container">
      <h2 className='form-title'>{t('contact_form_title')}</h2>
    <form className="contact-form" onSubmit={handleSubmit}>
      <label htmlFor='name'>{t ('contact_form_name')}</label>
      <input type="text" id='name' placeholder={t ('contact_form_name')} required value={name} onChange={(e) => setName(e.target.value)} />

      <label htmlFor='email'>{t ('contact_form_email')}</label>
      <input type="email" id='email' placeholder={t ('contact_form_email')} required value={email} onChange={(e) => setEmail(e.target.value)} />

      <label htmlFor='message'>{t ('contact_form_message')}</label>
      <textarea id='message' placeholder={t ('contact_form_message')} required value={message} onChange={(e) => setMessage(e.target.value)}></textarea>

      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LczytYpAAAAAEDASZYZxHoK3xlAGJJkKEwgENAm"
        onChange={handleCaptchaChange}
      />

      <button type="submit" disabled={isSubmitting}>{t ('contact_form_submit')}</button>
      <p>{responseMessage}</p>
    </form>
    </div>
  );
};

export default ContactForm;