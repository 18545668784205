import React, { useEffect, useRef } from 'react';
import '../App.css'; // Ensure the CSS file is imported

const Map = ({ location, zoomLevel }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const initializeMap = () => {
      if (window.google && window.google.maps) {
        const map = new window.google.maps.Map(mapRef.current, {
          center: location,
          zoom: zoomLevel,
        });

        new window.google.maps.Marker({
          position: location,
          map: map,
        });
      } else {
        console.error('Google Maps API not loaded');
      }
    };

    const intervalId = setInterval(() => {
      if (window.google && window.google.maps) {
        initializeMap();
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [location, zoomLevel]);

  return (
    <div className="map-container">
      <div ref={mapRef} className="map"></div>
    </div>
  );
};

export default Map;
