import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { store } from './redux/store';

const getInitialLanguage = () => store.getState().language || 'en';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: getInitialLanguage(),
    debug: true,
    supportedLngs: ['en', 'ar'],
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    detection: {
      order: ['sessionStorage', 'queryString', 'cookie'],
      cache: ['sessionStorage', 'cookie'],
    },
    react: {
      useSuspense: false,
    },
  });

console.log('i18n initialized with language: ', i18n.language);

export default i18n;
