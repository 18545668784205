// languageReducer.js
import { CHANGE_LANGUAGE } from '../actions/languageActions';

const initialState = 'en'; // default language

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};

export default languageReducer;
