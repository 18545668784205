import React, { useState, useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { useTranslation } from 'react-i18next';
import property1 from '../images/carousel/photo1.jpg';
import property2 from '../images/carousel/photo2.jpg';
import property3 from '../images/carousel/photo3.jpg';
import property4 from '../images/carousel/photo4.jpg';
import property5 from '../images/carousel/photo5.jpg';
import property6 from '../images/carousel/photo6.jpg';
import property7 from '../images/carousel/photo7.jpg';
import property8 from '../images/carousel/photo8.jpg';
import property9 from '../images/carousel/photo9.jpg';
import property10 from '../images/carousel/photo10.jpg';
import property11 from '../images/carousel/photo11.jpg';
import property12 from '../images/carousel/photo12.jpg';
import property13 from '../images/carousel/photo13.jpg';
import property14 from '../images/carousel/photo14.jpg';
import property15 from '../images/carousel/photo15.jpg';
import debounce from 'lodash.debounce';

export function PropertyCarousel() {
  const { i18n } = useTranslation();
  const [direction, setDirection] = useState(i18n.language === 'ar' ? 'rtl' : 'ltr');

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
      align: 'center',
      direction: direction,
      startIndex: direction === 'rtl' ? 14 : 0, // Start at the last slide if RTL
    },
    [Autoplay()]
  );

  const debouncedSetDirection = debounce(setDirection, 300); // Adjust debounce time as needed


  useEffect(() => {
    const handleLanguageChange = () => {
      const newDirection = i18n.language === 'ar' ? 'rtl' : 'ltr';
      debouncedSetDirection(newDirection);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
      debouncedSetDirection.cancel(); // Cancel any pending execution of debouncedSetDirection
    };
  }, [i18n, debouncedSetDirection]);

  const reInitEmbla = useCallback(() => {
    if (emblaApi && emblaApi.canScrollPrev && emblaApi.canScrollNext) {
      console.log('Reinitializing Embla with direction:', direction);
      emblaApi.reInit({ direction: direction });
      setTimeout(() => {
        emblaApi.scrollTo(direction === 'rtl' ? 14 : 0); // Scroll to the first or last slide
      }, 1000);
    }
  }, [emblaApi, direction]);

  useEffect(() => {
    console.log('Embla API:', emblaApi);
    reInitEmbla();
  }, [reInitEmbla]);

  useEffect(() => {
    if (emblaApi) {
      const onSelect = () => {
        emblaApi.slideNodes().forEach((slide) => slide.classList.remove('is-active'));
        const centralIndex = emblaApi.selectedScrollSnap();
        console.log('Selected slide index:', centralIndex);
        emblaApi.slideNodes()[centralIndex].classList.add('is-active');
      };

      console.log('Initializing Embla with direction:', direction);
      onSelect();
      emblaApi.on('select', onSelect);
    }
  }, [emblaApi, direction]);

  return (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        {[
          property1,
          property2,
          property3,
          property4,
          property5,
          property6,
          property7,
          property8,
          property9,
          property10,
          property11,
          property12,
          property13,
          property14,
          property15,
        ].map((src, index) => (
          <div className="embla__slide" key={index}>
            <img src={src} alt={`property ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PropertyCarousel;
